import { Box, ButtonPrimary, GridLayout, Hero, Image, Inline, LoadingBar, ResponsiveLayout, skinVars, Stack, Text, Text3, Text5, Text6, Text8, TextLink, useScreenSize } from '@telefonica/mistica';
import ButtonGroup from '@telefonica/mistica/dist/button-group';
import { useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import EmptyStateCustom from '../../../../components/empty-state/EmptyStateCustom';
import HeroShadow from '../../../../components/hero/HeroShadow';
import LayoutPublic from '../../../../components/layout/LayoutPublic';
import MembersOf from '../../../../components/staticPages/membersOf/MembersOf';
import { getDocument, getDocumentsSustainabilityWeb, getPublicDocuments } from '../../../../utils/apiclient';
import '../LandingPage.scss';
import { DocumentSustainability } from '../../../../entities/sustainability/document-sustainability.model';

const IsrInFonditelPage: React.FC = () => {
    const intl = useIntl()
    const size = useScreenSize();
    const screenSize = useScreenSize();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [documentoPDIPP, setDocumentoPDIPP] = useState({} as DocumentSustainability);
    const [documentoPDIFI, setDocumentoPDIFI] = useState({} as DocumentSustainability);
    const [documentoPDIMPP, setDocumentoPDIMPP] = useState({} as DocumentSustainability);
    const [documentoPDIMFI, setDocumentoPDIMFI] = useState({} as DocumentSustainability);
    const [documentoCAPP, setDocumentoCAPP] = useState({} as DocumentSustainability);
    const [documentoPCPP, setDocumentoPCPP] = useState({} as DocumentSustainability);
    const [documentoIVFI, setDocumentoIVFI] = useState({} as DocumentSustainability);
    const [documentoMS, setDocumentoMS] = useState({} as DocumentSustainability);
    const [documentoIBP, setDocumentoIBP] = useState({} as DocumentSustainability);
    const [options, setOptions] = useState(['Sobre Fonditel', 'ISR en Fonditel', 'Filosofia ISR', 'Inversión ISR', 'Divulgación (SFDR)']);

    const [loadingDownload, setLoadingDownload] = useState(false);

    const downloadDocument= (filename:string, name:string) => {
        setLoadingDownload(true);
        getPublicDocuments(filename, name).finally(()=>{
            setLoadingDownload(false);
        });
    };

    const downloadDocuments= (document:DocumentSustainability) => {
        
        if(document.ruta && document.cdDocumento){
            setLoadingDownload(true);
            // setLoadingDocument(document.cdDocumento);
            getDocument(document.ruta, document.cdDocumento).finally(()=>{
                setLoadingDownload(false);
                // setLoadingDocument(null);
            })
        }
    };

    useLayoutEffect(()=>{
        setLoadingDownload(true);
        getDocumentsSustainabilityWeb().then(result=>{
            if (Array.isArray(result)) {
            result.map(item => {
                
                switch (true) {
                  case item.EsPIS && item.compañia === "1":
                    setDocumentoPDIPP(item);
                    break;
                  case item.EsPIS && item.compañia === "3":
                    setDocumentoPDIFI(item);
                    break;
                  case item.EsPDIM && item.compañia === "1":
                    setDocumentoPDIMPP(item);
                    break;
                  case item.EsPDIM && item.compañia === "3":
                    setDocumentoPDIMFI(item);
                    break;
                  case item.EsCA && item.compañia === "1":
                    setDocumentoCAPP(item);
                    break;
                  case item.EsPC && item.compañia === "1":
                    setDocumentoPCPP(item);
                    break;
                  case item.EsIV && item.compañia === "3":
                    setDocumentoIVFI(item);
                    break;
                case item.EsMS && item.compañia === "1":
                    setDocumentoMS(item);
                    break;
                case item.EsIBP && item.compañia === "1":
                    setDocumentoIBP(item);
                    break;
                  default:
                    break;
                }
              });
            }
        }).finally(()=>{
            setLoadingDownload(false);
        })
    }, []);

    const data1 = {
        labels: ['Social', 'Gobernanza', 'Ambiental'],
        datasets: [{
            label: '',
            data: [51.5, 5.3, 43.2],
            backgroundColor: [
                '#0066FF',
                '#E7C2F8',
                '#F5E98A',
                '#E3A19A',
                '#67E0E5',
            ],
            hoverOffset: 4
        }],
    };

    const data2 = {
        labels: ['Renta Variable', 'Inversiones Líquidas', 'Renta fija'],
        datasets: [{
            label: '',
            data: [34.8, 12.4, 52.8],
            backgroundColor: [
                '#0066FF',
                '#E7C2F8',
                '#F5E98A',
                '#E3A19A',
                '#67E0E5',
            ],
            hoverOffset: 4
        }],
    };

    const optionsGraphics = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'  as any
            }
        },
        transitions: {
            active: {
                animation: {
                duration: 0
                }
            }
        }
    };

    return (
        <LayoutPublic
        seo={{metaTitle: intl.formatMessage({id:'page_sustainability_meta_title'}), metaDescription: intl.formatMessage({id:'page_sustainability_meta_description'})}}
        >
            <HeroShadow
                image='../assets/img/sostenibilidad.jpg'
                title={<Text8>{intl.formatMessage({id:'page_sustainability_title_black1'})} <Text color={skinVars.colors.backgroundBrand}>{intl.formatMessage({id:'page_sustainability_title_blue1'})}</Text> {intl.formatMessage({id:'page_sustainability_title_black2'})} <Text color={skinVars.colors.backgroundBrand}>{intl.formatMessage({id:'page_sustainability_title_blue2'})}</Text></Text8>}
            ></HeroShadow>

            <style>{
                '.landingPage--content .html-content *{color:'+skinVars.colors.textSecondary+';}'+
                '.landingPage--content .isotopeGrid:before{background:'+skinVars.colors.backgroundContainerAlternative+';}'+
                '.landingPage--content .html-content.chipTabs--content *{color:'+skinVars.colors.textPrimary+';}'
            }</style>
            <LoadingBar visible={loadingDownload}></LoadingBar>
            <ResponsiveLayout className={(selectedIndex===0 ? 'landingPage--content d-block' : 'd-none')}>

                <Hero
                    background="default"
                    media={
                        <Image src='../assets/img/sostenibilidad-hero2.jpg' aspectRatio={screenSize.isDesktopOrBigger ? '7:10' : '16:9'} />
                    }
                    title={intl.formatMessage({id:'page_sustainability_title_mainstreaming_sustainability'})}
                    extra={
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_mainstreaming_sustainability'})}}></div>
                    }
                    desktopMediaPosition="right"
                />

                <Hero
                    background="default"
                    media={
                        <Image src='../assets/img/sostenibilidad-hero4.jpg' aspectRatio='16:9' />
                    }
                    title={intl.formatMessage({id:'page_sustainability_title_isr_source_value'})}
                    extra={
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_isr_source_value'})}}></div>
                    }
                    desktopMediaPosition="left"
                />
                <div className='hero--reduceSize'>
                    <Hero
                        background="default"
                        media={
                            <div className='hero--hiddenImage html-content'><Image src='' aspectRatio='16:9' /></div>
                        }
                        title={intl.formatMessage({id:'page_sustainability_title_isr_philosophy'})}
                        extra={
                            <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_isr_philosophy'})}}></div>
                        }
                        desktopMediaPosition="right"
                    />
                </div>

                <Hero
                    background="default"
                    media={
                        <Image src='../assets/img/sostenibilidad-pri.jpg' aspectRatio='16:9' />
                    }
                    title={intl.formatMessage({id:'page_sustainability_title_principles_responsible_investment'})}
                    extra={
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_principles_responsible_investment'})}}></div>
                    }
                    desktopMediaPosition="left"
                />

                <Hero
                    background="default"
                    media={
                        <Image src='../assets/img/sostenibilidad-objetivos.jpg' aspectRatio='16:9' />
                    }
                    title={intl.formatMessage({id:'page_sustainability_title_sustainable_development_goals'})}
                    extra={
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_sustainable_development_goals'})}}></div>
                    }
                    desktopMediaPosition="right"
                />

                <ResponsiveLayout>

                    <EmptyStateCustom
                        largeImageUrl="../assets/img/informe-inverco-fondos-de-inversion.jpg"
                        title={intl.formatMessage({id:'page_sustainability_empty_state_title'})}
                        description={intl.formatMessage({id:'page_sustainability_empty_state_subtitle'})}
                        button={
                            <ButtonGroup
                                primaryButton={
                                    <ButtonPrimary onPress={() => {downloadDocuments(documentoMS)}}>{intl.formatMessage({id:'page_sustainability_empty_state_button'})}</ButtonPrimary>
                                }
                            ></ButtonGroup>
                        }
                    />

                </ResponsiveLayout>

                <Box className='isotopeGrid' paddingTop={56} paddingBottom={screenSize.isDesktopOrBigger ? 64 : 56}>
                    <ResponsiveLayout>
                        <Stack space={40} className='isotopeGrid__content'>
                            <Stack space={screenSize.isDesktopOrBigger ? 64 : 40}>
                                <Stack space={screenSize.isDesktopOrBigger ? 40 : 24}>
                                    <Box className='boxAlignCenter'>
                                        <Text6>{intl.formatMessage({id:'page_sustainability_title_sustainability_policies'})}</Text6>
                                    </Box>
                                    <Box className='boxAlignCenter'>
                                        <GridLayout verticalSpace={16} template='8+4'
                                            left={
                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <Text5>{intl.formatMessage({id:'page_sustainability_fonditel_pensions_sustainability_policies'})}</Text5>
                                                    }
                                                    right={
                                                        <ButtonPrimary small onPress={() => {downloadDocuments(documentoPDIPP)}}>{intl.formatMessage({id:'page_sustainability_fonditel_button_investment_policy'})}</ButtonPrimary>
                                                    }></GridLayout>
                                            }
                                            right={
                                                <ButtonPrimary small onPress={() => {downloadDocuments(documentoPDIMPP)}}>{intl.formatMessage({id:'page_sustainability_fonditel_button_involvement_policy'})}</ButtonPrimary>
                                        }/>      
                                    </Box>
                                    <Box className='boxAlignCenter'>
                                        <GridLayout verticalSpace={16} template='8+4'
                                            left={
                                                <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                        <Text5>{intl.formatMessage({id:'page_sustainability_fonditel_management_sustainability_policies'})}</Text5>
                                                    }
                                                    right={
                                                        <ButtonPrimary small onPress={() => {downloadDocuments(documentoPDIFI)}}>{intl.formatMessage({id:'page_sustainability_fonditel_button_investment_policy'})}</ButtonPrimary>
                                                    }></GridLayout>
                                            }
                                            right={
                                                <ButtonPrimary small onPress={() => {downloadDocuments(documentoPDIMFI)}}>{intl.formatMessage({id:'page_sustainability_fonditel_button_involvement_policy'})}</ButtonPrimary>
                                            }/>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    </ResponsiveLayout>
                </Box>                  

                <Box paddingY={24}>
                    <Stack space={16}>
                        <Text3 medium>{intl.formatMessage({id:'page_sustainability_title_policy_statement'})}</Text3>
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_policy_statement'})}}></div>
                        <Text3 medium>{intl.formatMessage({id:'page_sustainability_title_remuneration_policies'})}</Text3>
                        <div className='hero--extraContent html-content' dangerouslySetInnerHTML={{__html:intl.formatMessage({id:'page_sustainability_description_remuneration_policies'})}}></div>
                        <Text3 medium>
                            {intl.formatMessage({id:'page_sustainability_adherence_good_faith'})}
                        </Text3>
                        <div className='hero--extraContent html-content' ><p>El 23 de junio de 2023, Fonditel Pensiones EGFP, S.A. se adhirió al Código de buenas prácticas de inversores de la Comisión Nacional del Mercado de Valores, acogiéndose al periodo transitorio.</p></div>
                        <Inline space={12}>
                            <ButtonPrimary small onPress={() => {downloadDocuments(documentoCAPP)}}>Carta de Adhesión al Código de Buenas Prácticas</ButtonPrimary>
                            <ButtonPrimary small onPress={() => {downloadDocuments(documentoPCPP)}}>Plan y calendario de adaptación al Código de Buenas Prácticas</ButtonPrimary>
                            <ButtonPrimary small onPress={() => {downloadDocuments(documentoIBP)}}>Informe Anual Código de Buenas Prácticas</ButtonPrimary>
                        </Inline>           
                        <Text3 medium> {intl.formatMessage({id:'page_sustainability_vote_report_title'})}
                        </Text3>
                        <div className='hero--extraContent html-content' ><p>{intl.formatMessage({id:'page_sustainability_vote_report_description'})}</p></div>
                        <Inline space={12}>
                            <ButtonPrimary small onPress={() => {downloadDocuments(documentoIVFI)}}>{intl.formatMessage({id:'page_sustainability_vote_report_button'})}</ButtonPrimary>
                        </Inline>  
                    </Stack>
                </Box>
            </ResponsiveLayout>

            <ResponsiveLayout className='content' fullWidth>
                <MembersOf></MembersOf>
            </ResponsiveLayout>
        </LayoutPublic>
    );
};

export default IsrInFonditelPage;

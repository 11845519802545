import { useIonRouter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';

import EmptyCard from '../../../../../components/empty-card/EmptyCard';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import ConfigureOnlineCorrespondenceCustomerPage from './ConfigureOnlineCorrespondenceCustomerPage';

import { DocumentSearch } from '../../../../../entities/onlineCorrespondence/document-search.model';
import { DocumentCO } from '../../../../../entities/onlineCorrespondence/document.model';
import { getCartaTraspaso, getCertificadoAportacionesPP, getCertificadoAportacionesPPConyuges, getCertificadoAportacionesPPMinusvalidos, getCertificadoRetencionesPP, getDocument, getDocumentsOnlineCorrespondence, getDocumentsTypeCompaniesOnlineCorrespondence, getDocumentsTypeOnlineCorrespondence, getDocumentWithAuthorization, getExtractoFI, getExtractoPP, getFiniquitoPP, getInformacionFiscalFI, getReconocimientoDerechos } from '../../../../../utils/apiclient';
import { getUrlNavigation } from '../../../../../utils/navigation';
import { isIOS, isNativeApp, trackEvent } from '../../../../../utils/utils';

import { Box, Boxed, ButtonLayout, ButtonPrimary, ButtonSecondary, DateField, Divider, Form, GridLayout, IconButton, IconControlsRegular, IconDownloadRegular, IconSearchFileRegular, IconSettingsRegular, Inline, LoadingBar, ResponsiveLayout, Select, SkeletonRectangle, skinVars, Stack, Text, Text2, Text3, Text4, Text5, Touchable, useScreenSize } from '@telefonica/mistica';

import { removeCurrentUserProduct } from '../../../../../utils/storage';
import './OnlineCorrespondenceCustomerPage.scss';


const OnlineCorrespondenceCustomerPage: React.FC = () => {
  const intl = useIntl();
  const screenSize = useScreenSize();
  const router = useIonRouter();
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(null as number|null);

  const currentDate = moment().format("YYYY-MM-DD").toString();
  const threeMonthsAgo = moment().subtract(3, 'months').format("YYYY-MM-DD").toString();
  const sixMonthsAgo = moment().subtract(6, 'months').format("YYYY-MM-DD").toString();
  const oneYearAgo = moment().subtract(1, 'year').format("YYYY-MM-DD").toString();
  const initSearch = {cdCompany: "-1", documentType: "-1", dateFrom: sixMonthsAgo, dateTo: currentDate, periodo: "6"} as DocumentSearch;
  const [disabledDates, setDisabledDates] = useState(true);

  const [dateFrom, setDateFrom] = useState(sixMonthsAgo);
  const [dateTo, setDateTo] = useState(currentDate);
  const [periodo, setPeriodo] = useState('6');
  const [documentType, setDocumentType] = useState('-1');


  const [searchDocuments, setSearchDocuments] = useState(initSearch);
  const [documentsCO, setDocumentsCO] = useState([] as DocumentCO[]);
  const [documentsTypeCO, setDocumentsTypeCO] = useState<{ value: string, text: string }[]>([]);
  const [documentsTypeCompanyCO, setDocumentsTypeCompanyCO] = useState<{ value: string, text: string }[]>([]);



  useEffect(()=>{
    removeCurrentUserProduct();
    setLoading(true);

    getDocumentsTypeCompaniesOnlineCorrespondence().then(documentsTypeC => {
      const updatedDocumentsTypeCompanyCO = documentsTypeC.map(type => ({
        value: type.cdCompania.toString(),
        text: type.nombre
      }));

      updatedDocumentsTypeCompanyCO.unshift({value: "-1", text: intl.formatMessage({id:'page_customer_online_correspondence_all'})});
      setDocumentsTypeCompanyCO(updatedDocumentsTypeCompanyCO);
    });

    const updatedDocumentsTypeCO = [];

    updatedDocumentsTypeCO.push({value: "-1", text: intl.formatMessage({id:'page_customer_online_correspondence_all2'})});
    setDocumentsTypeCO(updatedDocumentsTypeCO);

    getDocumentsOnlineCorrespondence(searchDocuments).then(documents => {
      setLoading(false);
      if(documents)
        setDocumentsCO(documents);
    });

  }, []);

  const handleChangeAnyField= (name:string, value:any) => {
    const newValues = {
      ...searchDocuments,
      [name]: value,
    };
    setSearchDocuments(newValues);

    switch(name){
      case 'cdCompany':
        if(value===-1){
          const updatedDocumentsTypeCO = [];
          updatedDocumentsTypeCO.push({value: "-1", text: intl.formatMessage({id:'page_customer_online_correspondence_all2'})});
          setDocumentsTypeCO(updatedDocumentsTypeCO);
        }else{
          getDocumentsTypeOnlineCorrespondence(parseInt(value)).then(documentsType => {
            const updatedDocumentsTypeCO = documentsType.map(type => ({
              value: type.cdDocumento.toString(),
              text: type.descripcion
            }));

            updatedDocumentsTypeCO.unshift({value: "-1", text: intl.formatMessage({id:'page_customer_online_correspondence_all2'})});
            setDocumentsTypeCO(updatedDocumentsTypeCO);
          });
          setDocumentType('-1');
        }
        break;
      case 'documentType':
        setDocumentType(value);
        break;
      case 'dateFrom':
        if(!((value===threeMonthsAgo || value===sixMonthsAgo || value===oneYearAgo) && searchDocuments.dateTo===currentDate)){
          setPeriodo('0');
        }
        setDateFrom(value);
        break;
      case 'dateTo':
        if(!((searchDocuments.dateFrom===threeMonthsAgo || searchDocuments.dateFrom===sixMonthsAgo || searchDocuments.dateFrom===oneYearAgo) && value===currentDate)){
          setPeriodo('0');
        }
        setDateTo(value);
        break;
      case 'periodo':
        setPeriodo(value);
        switch(value){
          case '3':
            setDateFrom(threeMonthsAgo);
            setDateTo(currentDate);
            break;
          case '6':
            setDateFrom(sixMonthsAgo);
            setDateTo(currentDate);
            break;
          case '12':
            setDateFrom(oneYearAgo);
            setDateTo(currentDate);
            break;
        }
        break;
    }
  };

  const handleSubmit = (data: any): Promise<void> =>
  new Promise((resolve) => {
    trackEvent('perfil-correspondencia-buscar');
    setLoading(true);
    setShowFilter(false);

    getDocumentsTypeOnlineCorrespondence(data.cdCompany).then(documentsType => {
      const updatedDocumentsTypeCO = documentsType.map(type => ({
        value: type.cdDocumento.toString(),
        text: type.descripcion
      }));

      updatedDocumentsTypeCO.unshift({value: "-1", text: intl.formatMessage({id:'page_customer_online_correspondence_all2'})});
      setDocumentsTypeCO(updatedDocumentsTypeCO);
    });

    getDocumentsOnlineCorrespondence(data).then(documents => {
      setLoading(false);
      if(documents){
        setDocumentsCO(documents);
      }
      setSearchDocuments(data);
    });
    resolve();
  });

  const downloadDocument= (document:DocumentCO, index:number) => {
    setLoadingDownload(true);
    setLoadingDocument(index);
    getDocumentWithAuthorization(document.ruta, document.cdDocumento).finally(()=>{
      setLoadingDownload(false);
      setLoadingDocument(null);
    });
    /*switch(document.cdDocumento){
      case 1:
        // Documento de informacion fiscal de fondos de inversion
        getInformacionFiscalFI(document.nombreDocumento, document.extra.identificador, document.extra.ordenDomicilio, document.extra.anio, document.extra.fechaEmision, document.extra.cuenta).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
      case 2:
        // Documento extracto fondos de inversion
        getExtractoFI(document.nombreDocumento, document.extra.codigoUnico, document.extra.ordenDomicilio, document.extra.fechaDesde, document.extra.fechaHasta, document.extra.cuenta).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
      case 3:
        // Documento certificados de retenciones de planes de pensiones
        getCertificadoRetencionesPP(document.nombreDocumento, document.extra.codigo_fondo, document.extra.codigo_plan, document.extra.codigo_subPlan, document.extra.ejercicioFiscal).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
      case 4:
        // Documento de extracto de planes de pensiones
        getExtractoPP(document.nombreDocumento, document.extra.RangoFechaDesde, document.extra.RangoFechaHasta, document.extra.GrupoImpresion).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
      case 5:
        // Documento de certificado de aportaciones de planes de pensiones
        getCertificadoAportacionesPP(document.nombreDocumento, document.extra.codigo_fondo, document.extra.codigo_plan, document.extra.codigo_subPlan, document.extra.ejercicioFiscal).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
      case 10:
        // Documento de reconocimiento de derechos
        getReconocimientoDerechos(document.nombreDocumento, document.extra.codigo_fondo, document.extra.codigo_plan, document.extra.codigo_subplan, document.extra.contrato, document.extra.cdContingencia, document.extra.fecha, document.extra.numeral).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
      case 11:
        // Documento de certificado de aportaciones a minusvalidos de planes de pensiones
        getCertificadoAportacionesPPMinusvalidos(document.nombreDocumento, document.extra.codigo_fondo, document.extra.codigo_plan, document.extra.codigo_subPlan, document.extra.ejercicioFiscal, document.extra.nif_Minusvalido, document.extra.numeroContrato_Minusvalido).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
      case 12:
        // Documento de certificado de aportaciones a conyuges de planes de pensiones
        getCertificadoAportacionesPPConyuges(document.nombreDocumento, document.extra.codigo_fondo, document.extra.codigo_plan, document.extra.codigo_subPlan, document.extra.ejercicioFiscal, document.extra.nif_Conyuge, document.extra.numeroContrato_Conyuge).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
      case 13:
      case 14:
        // Documento finiquito o retenciones de planes de pensiones
        getFiniquitoPP(document.nombreDocumento, document.extra.fechaDatos, document.extra.codigo_fondo, document.extra.codigo_plan, document.extra.codigo_subPlan, document.anio, document.extra.tipoDePrestacion, document.extra.capitalRentaMixto).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
      case 15:
        // Documento de carta de traspaso
        getCartaTraspaso(document.nombreDocumento, document.cuenta, document.fecha).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
      default:
        // Documentos subidos en mantenimiento de planes/fondos
        getDocument(document.ruta, document.cdDocumentoArchivo).finally(()=>{
          setLoadingDownload(false);
          setLoadingDocument(null);
        });
        break;
    }*/
  };

  return (
    <>
      <LoadingBar visible={loading || loadingDownload}/>
      {!screenSize.isDesktopOrBigger &&
        <Touchable className={'filter-button--onlineCorrespondence '+(isNativeApp ? ' is-native-app ' : '')+(isIOS ? ' is-ios ' : '')} onPress={()=>{setShowFilter(!showFilter)}}>
          <IconControlsRegular color={skinVars.colors.background}></IconControlsRegular>
        </Touchable>
      }
      <LayoutPrivateArea
        seo={{metaTitle: intl.formatMessage({id:'page_customer_online_correspondence_title'})}}
        title={intl.formatMessage({id:'page_customer_online_correspondence_title'})}
        pageInfo={{option: process.env.REACT_APP_MENU_CORRESPONDENCIA_ONLINE, role:'client'}}
        showMenu
      >

        {screenSize.isDesktopOrBigger &&
          <ResponsiveLayout className={'inner first'}>
              <Inline space={'between'} alignItems='center'>
                <Text5>{intl.formatMessage({id:'page_customer_online_correspondence_title'})}</Text5>
                <ButtonSecondary onPress={()=> router.push(getUrlNavigation(ConfigureOnlineCorrespondenceCustomerPage))}><IconSettingsRegular color='currentColor' />{intl.formatMessage({id:'page_customer_online_correspondence_configure_button'})}</ButtonSecondary>
            </Inline>
          </ResponsiveLayout>
        }

        <ResponsiveLayout className={'filter--onlineCorrespondence '+(screenSize.isDesktopOrBigger ? 'inner first ' : 'fullscreen fixed ')+(showFilter ? 'visible ' : 'hide')} isInverse={!screenSize.isDesktopOrBigger}>
          <Form onSubmit={handleSubmit} initialValues={searchDocuments}>
            <Box paddingBottom={screenSize.isDesktopOrBigger ? 40 : 16}>
              <GridLayout template='8+4' verticalSpace={16}
                left={
                  <GridLayout template='6+6'
                    left={
                      <Stack space={16}>
                        <Select
                          fullWidth
                          name="cdCompany"
                          label={intl.formatMessage({id:'page_customer_online_correspondence_field_company'})}
                          options={documentsTypeCompanyCO}
                          onChangeValue={(cdCompany)=> handleChangeAnyField('cdCompany', cdCompany)}
                        />

                        <Select
                          fullWidth
                          name="documentType"
                          label={intl.formatMessage({id:'page_customer_online_correspondence_field_document_type'})}
                          options={documentsTypeCO}
                          value={documentType}
                          onChangeValue={(data)=> handleChangeAnyField('documentType', data)}
                        />
                      </Stack>
                    }
                    right={
                      <Stack space={16}>
                        <DateField
                          fullWidth
                          name="dateFrom"
                          label={intl.formatMessage({id:'page_customer_online_correspondence_field_from'})}
                          value={dateFrom}
                          onChangeValue={(data)=> handleChangeAnyField('dateFrom', data)}
                        />

                        <DateField
                          fullWidth
                          name="dateTo"
                          label={intl.formatMessage({id:'page_customer_online_correspondence_field_to'})}
                          value={dateTo}
                          onChangeValue={(data)=> handleChangeAnyField('dateTo', data)}
                        />
                      </Stack>
                    }
                  />
                }
                right={
                  <Stack space={16}>
                    <Select
                      fullWidth
                      name="periodo"
                      label={intl.formatMessage({id:'page_customer_online_correspondence_field_period'})}
                      options={[
                        { value: "0", text: intl.formatMessage({id:'page_customer_online_correspondence_date_range'}) },
                        { value: "3", text: intl.formatMessage({id:'page_customer_online_correspondence_last_3_months'}) },
                        { value: "6", text: intl.formatMessage({id:'page_customer_online_correspondence_last_6_months'}) },
                        { value: "12", text: intl.formatMessage({id:'page_customer_online_correspondence_last_12_months'})},
                      ]}
                      value={periodo}
                      onChangeValue={(data)=> handleChangeAnyField('periodo', data)}
                    />
                    <ButtonLayout>
                      <ButtonPrimary submit>{intl.formatMessage({id:'page_customer_online_correspondence_search_button'})}</ButtonPrimary>
                    </ButtonLayout>
                  </Stack>
                }
              />
            </Box>
          </Form>
        </ResponsiveLayout>
        <ResponsiveLayout className='inner'>
          {!loading && documentsCO.length === 0 ? (
            <Box paddingY={40}>
              <EmptyCard
                icon={<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                title= {intl.formatMessage({id:'page_customer_online_correspondence_empty_card_title'})}
                subtitle={intl.formatMessage({id:'page_customer_online_correspondence_empty_card_subtitle'})}
              />
            </Box>
          ) : (
            screenSize.isDesktopOrBigger ?
              <Box paddingBottom={40}>
                <Stack space={8} className='table--onlineCorrespondence'>
                  <Inline space="between">
                    <Box className='col-1'>
                      <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_customer_online_correspondence_column_document'})}</Text>
                    </Box>
                    <Box className='col-2'>
                      <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_customer_online_correspondence_column_product'})}</Text>
                    </Box>
                    <Box className='col-3'>
                      <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_customer_online_correspondence_column_account'})}</Text>
                    </Box>
                    <Box className='col-4'>
                      <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_customer_online_correspondence_column_date'})}</Text>
                    </Box>
                    <Box className='col-5'>
                      <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_customer_online_correspondence_column_action'})}</Text>
                    </Box>
                  </Inline>
                  <Divider/>
                  {loading ?
                    <Stack space={8}>
                      <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                      <Divider />
                      <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                      <Divider />
                      <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                    </Stack>
                  :
                    documentsCO.map((documento, index) =>(
                      <Stack key={index} space={0}>
                        <Inline space='between' alignItems='center' className='row'>
                          <Box className='col-1'>
                            <Text2 regular>{documento.nombreDocumento}</Text2>
                          </Box>
                          <Box className='col-2'>
                            <Text2 regular>{documento.denominacionProducto}</Text2>
                          </Box>
                          <Box className='col-3'>
                            <Text2 regular>{documento.extra.cuenta}</Text2>
                          </Box>
                          <Box className='col-4'>
                            <Text2 regular>{documento.fecha}</Text2>
                          </Box>
                          <Box className='col-5'>
                            <IconButton  onPress={()=>downloadDocument(documento, index)}>
                              <IconDownloadRegular color={skinVars.colors.buttonPrimaryBackground}></IconDownloadRegular>
                            </IconButton>
                          </Box>
                        </Inline>
                        <Divider/>
                      </Stack>
                    ))
                  }
                </Stack>
              </Box>
            :
              <Box paddingY={12}>
                {loading ?
                  <Stack space={12}>
                    <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                    <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                    <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                  </Stack>
                :
                  <Stack space={12}>
                    {documentsCO.map((documento, index) => (
                      <Boxed key={index}>
                        <Box padding={16}>
                          <Stack space={24}>
                            <Text4 medium>{documento.nombreDocumento}</Text4>

                            <Stack space={2}>
                              <Text>{documento.fecha}</Text>
                              <Text3 medium>{documento.denominacionProducto}</Text3>
                            </Stack>

                            <ButtonSecondary showSpinner={index===loadingDocument} small onPress={() => downloadDocument(documento, index)}>
                              {intl.formatMessage({id:'page_customer_online_correspondence_download_button'})}
                              <IconDownloadRegular color='currentColor' />
                            </ButtonSecondary>
                          </Stack>
                        </Box>
                      </Boxed>
                    ))}
                  </Stack>
                }
              </Box>
            )
          }
        </ResponsiveLayout>

      </LayoutPrivateArea>

    </>
  );
};

export default OnlineCorrespondenceCustomerPage;
